<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#005f32"
      spinner="bar-fade-scale"
    />
    <v-snackbar v-model="showsnackbar" color="black" right>
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showsnackbar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap>
      <v-flex>
        <v-card-title class="elevation-0">
          <span
            style="text-transform: uppercase"
            class="itemHeading"
            v-if="$route.query.name && $route.query.empid"
            >ATTENDANCE of {{ $route.query.name }} (Employee ID:
            {{ $route.query.empid }})</span
          >
          <span style="text-transform: uppercase" class="itemHeading" v-else
            >ATTENDANCE</span
          >
          <!-- <v-flex xs12 align-self-center py-2>
            <v-layout wrap justify-start>
              <v-flex xs12 sm6 md3 lg3 xl3  pb-2>
                <v-menu ref="menu2" v-model="menu2" :close-on-content-click="false" transition="scale-transition" offset-y
                  max-width="290px" min-width="auto">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field v-model="computedDateFormatted" outlined hide-details dense v-bind="attrs" v-on="on"
                      clearable class="rounded-xl"></v-text-field>
                  </template>
                  <v-date-picker v-model="date" no-title type="month" @input="menu2 = false"></v-date-picker>
                </v-menu>
              </v-flex>
              <v-spacer></v-spacer>
              <v-flex xs12 sm6 md3 lg2 xl2 pb-2 text-right>
                <v-btn
                  style="font-family: kumbhBold; text-transform: capitalize; font-size: 14px;"
                  dark
                  block
                  class="rounded-xl"
                  color="#005f32"
                  @click="eachEmployee()"
                >
                Excel Download
                </v-btn>
              </v-flex>
            </v-layout>
          </v-flex> -->
          <v-flex xs12 align-self-center pt-2>
            <v-layout wrap justify-start>
              <v-flex
                xs12
                sm3
                md3
                lg3
                xl3
                pa-1
                text-left
                style="font-family: poppinsregular"
              >
                <span style="font-family: kumbhRegular; font-size: 14px"
                  >Pick a date range</span
                >
                <v-select
                  v-model="day"
                  :items="days"
                  item-text="days"
                  item-value="days"
                  outlined
                  class="rounded-xl"
                  dense
                  @change="dateFilter(day)"
                ></v-select>
              </v-flex>
              <v-flex
                xs12
                sm3
                md2
                text-left
                pa-1
                v-if="day == 'Custom'"
                style="font-family: poppinsregular"
              >
                <span style="font-family: kumbhRegular; font-size: 14px"
                  >Pick from date</span
                >
                <v-menu
                  ref="menu1"
                  v-model="menu1"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="computedDateFormattedFrom"
                      outlined
                      class="rounded-xl"
                      clearable
                      dense
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="fromDate"
                    no-title
                    @input="menu1 = false"
                  ></v-date-picker>
                </v-menu>
              </v-flex>
              <v-flex
                xs12
                sm3
                md2
                text-left
                pa-1
                v-if="day == 'Custom'"
                style="font-family: poppinsregular"
              >
                <span style="font-family: kumbhRegular; font-size: 14px"
                  >Pick to date</span
                >
                <v-menu
                  ref="menu2"
                  v-model="menu2"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="computedDateFormattedTo"
                      outlined
                      class="rounded-xl"
                      clearable
                      dense
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="toDate"
                    no-title
                    @input="menu2 = false"
                  ></v-date-picker>
                </v-menu>
              </v-flex>
              <v-flex
                xs12
                sm3
                md3
                lg2
                xl2
                pa-1
                pt-9
                text-left
                style="font-family: poppinsregular"
              >
                <v-btn
                  style="
                    font-family: kumbhBold;
                    text-transform: capitalize;
                    font-size: 14px;
                  "
                  dark
                  block
                  class="rounded-xl"
                  color="#005f32"
                  @click="eachEmployee()"
                >
                  Excel Download
                </v-btn>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-card-title>
        <v-layout wrap justify-start pa-5 v-if="attendanceArray.length > 0">
          <v-flex xs12>
            <v-simple-table class="align-center">
              <template v-slot:default>
                <thead style="background-color: #005f32">
                  <tr>
                    <th
                      style="
                        font-size: 15px;
                        color: #ffffff;
                        font-family: kumbhRegular;
                      "
                      class="text-left"
                    >
                      Attendance Date
                    </th>
                    <th
                      style="
                        font-size: 15px;
                        color: #ffffff;
                        font-family: kumbhRegular;
                      "
                      class="text-left"
                    >
                      Punch in
                    </th>
                    <th
                      style="
                        font-size: 15px;
                        color: #ffffff;
                        font-family: kumbhRegular;
                      "
                      class="text-left"
                    >
                      Punch out
                    </th>
                    <th
                      style="
                        font-size: 15px;
                        color: #ffffff;
                        font-family: kumbhRegular;
                      "
                      class="text-left"
                    >
                      Work Hours
                    </th>
                    <th
                      style="
                        font-size: 15px;
                        color: #ffffff;
                        font-family: kumbhRegular;
                      "
                      class="text-left"
                    >
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody class="align-center">
                  <tr
                    class="text-left align-center"
                    v-for="(item, i) in attendanceArray"
                    :key="i"
                  >
                    <td>
                      <span v-if="item.date" style="font-family: kumbhMedium">{{
                        item.date
                      }}</span>
                    </td>

                    <td>
                      <span
                        v-if="item.login && item.login != 'Not Marked'"
                        style="font-family: kumbhMedium"
                        >{{ convertUTCToLocal(item.login) }}</span
                      >
                      <span
                        v-if="item.login && item.login == 'Not Marked'"
                        class=""
                        style="font-family: kumbhMedium"
                        >{{ item.login }}</span
                      >
                    </td>

                    <td>
                      <span
                        v-if="item.logout && item.logout != 'Not Marked'"
                        class=""
                        style="font-family: kumbhMedium"
                        >{{ convertUTCToLocal(item.logout) }}</span
                      >
                      <span
                        v-if="item.logout && item.logout == 'Not Marked'"
                        class=""
                        style="font-family: kumbhMedium"
                        >{{ item.logout }}</span
                      >
                    </td>
                    <td>
                      <span
                        v-if="item.workedHours"
                        style="font-family: kumbhMedium"
                        >{{ item.workedHours }}</span
                      >
                    </td>
                    <td>
                      <v-layout wrap>
                        <v-flex xs12 align-self-center text-left pa-2>
                          <v-dialog
                            v-model="item.view"
                            persistent
                            max-width="70%"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                title="View"
                                color="#005f32"
                                v-on="on"
                                @click="getView(item)"
                                v-bind="attrs"
                                >mdi-eye</v-icon
                              >
                            </template>
                            <v-card>
                              <v-layout wrap>
                                <v-flex
                                  xs12
                                  align-self-center
                                  pa-4
                                  style="background: #005f32"
                                  text-left
                                >
                                  <v-layout wrap>
                                    <v-flex xs11>
                                      <span
                                        class="kumbhBold"
                                        style="color: #ffffff"
                                        >ATTENDANCE DETAILS</span
                                      >
                                    </v-flex>
                                    <v-flex xs1 text-right>
                                      <v-icon
                                        color="white"
                                        @click="
                                          (item.view = false), clearData()
                                        "
                                        >mdi-close</v-icon
                                      >
                                    </v-flex>
                                  </v-layout>
                                </v-flex>
                                <v-flex
                                  xs12
                                  align-self-center
                                  class="kumbhRegular"
                                >
                                  <v-layout wrap pa-4>
                                    <v-flex
                                      xs12
                                      v-for="info in data"
                                      :key="info._id"
                                    >
                                      <v-timeline
                                        :dense="$vuetify.breakpoint.smAndDown"
                                      >
                                        <v-timeline-item
                                          v-if="info.attendanceType == 'login'"
                                          color="#005f32"
                                          fill-dot
                                          right
                                        >
                                          <span
                                            slot="opposite"
                                            style="
                                              font-family: kumbhMedium;
                                              color: black;
                                            "
                                          >
                                            Punch In
                                            <br />
                                            <br />
                                            <span
                                              style="
                                                font-size: 16px;
                                                font-family: kumbhSemibold;
                                              "
                                            >
                                              {{
                                                convertUTCToLocal(
                                                  info.attendanceTime
                                                )
                                              }}</span
                                            ></span
                                          >
                                          <v-card>
                                            <v-card-title
                                              style="background-color: #005f32"
                                            >
                                            </v-card-title>
                                            <v-container>
                                              <v-row>
                                                <v-col
                                                  cols="12"
                                                  md="10"
                                                  class="text-left"
                                                >
                                                  <span v-if="info.address"
                                                    ><span
                                                      style="
                                                        font-size: 15px;
                                                        font-family: kumbhSemibold;
                                                      "
                                                      >Address: </span
                                                    >{{ info.address }}
                                                    <br />
                                                  </span>
                                                  <span v-if="info.city"
                                                    ><span
                                                      style="
                                                        font-size: 15px;
                                                        font-family: kumbhSemibold;
                                                      "
                                                      >City: </span
                                                    >{{ info.city }}
                                                    <br />
                                                  </span>
                                                  <span v-if="info.state"
                                                    ><span
                                                      style="
                                                        font-size: 15px;
                                                        font-family: kumbhSemibold;
                                                      "
                                                      >State: </span
                                                    >{{ info.state }}</span
                                                  >
                                                  <span
                                                    v-if="
                                                      !info.address &&
                                                      !info.city &&
                                                      !info.state
                                                    "
                                                    ><span
                                                      style="
                                                        font-size: 15px;
                                                        font-family: kumbhSemibold;
                                                      "
                                                      >Address not fetched</span
                                                    >
                                                  </span>
                                                  <p
                                                    v-if="
                                                      info.description != ''
                                                    "
                                                    style="
                                                      font-size: 16px;
                                                      color: black !important;
                                                    "
                                                  >
                                                    <b>Description</b>
                                                    <span>
                                                      <br />
                                                      {{
                                                        info.description.slice(
                                                          0,
                                                          100
                                                        )
                                                      }}
                                                    </span>
                                                  </p>
                                                  <div
                                                    v-if="
                                                      info.photos &&
                                                      info.photos.length
                                                    "
                                                  >
                                                    <b>Images</b><br />
                                                    <v-chip
                                                      v-for="(
                                                        photo, index
                                                      ) in info.photos"
                                                      :key="index"
                                                      color="#b1fad9"
                                                      class="ma-1"
                                                    >
                                                      {{ photo }}
                                                      &nbsp;<v-icon
                                                        class="mt-1"
                                                        small
                                                        @click="
                                                          openImageDialog(photo)
                                                        "
                                                        >mdi-eye</v-icon
                                                      >
                                                    </v-chip>
                                                  </div>
                                                </v-col>
                                              </v-row>
                                            </v-container>
                                          </v-card>
                                        </v-timeline-item>
                                        <v-timeline-item
                                          v-if="info.attendanceType == 'other'"
                                          color="#005f32"
                                          fill-dot
                                          right
                                        >
                                          <span
                                            slot="opposite"
                                            style="
                                              font-family: kumbhMedium;
                                              color: black;
                                            "
                                          >
                                            <br />
                                            <br />
                                            <span
                                              style="
                                                font-size: 16px;
                                                font-family: kumbhSemibold;
                                              "
                                            >
                                              {{
                                                convertUTCToLocal(
                                                  info.attendanceTime
                                                )
                                              }}</span
                                            ></span
                                          >
                                          <v-card>
                                            <v-card-title
                                              style="background-color: #005f32"
                                            >
                                            </v-card-title>
                                            <v-container>
                                              <v-row>
                                                <v-col
                                                  cols="12"
                                                  md="10"
                                                  class="text-left"
                                                >
                                                  <span v-if="info.address"
                                                    ><span
                                                      style="
                                                        font-size: 15px;
                                                        font-family: kumbhSemibold;
                                                      "
                                                      >Address: </span
                                                    >{{ info.address }}
                                                    <br />
                                                  </span>
                                                  <span v-if="info.city"
                                                    ><span
                                                      style="
                                                        font-size: 15px;
                                                        font-family: kumbhSemibold;
                                                      "
                                                      >City: </span
                                                    >{{ info.city }}
                                                    <br />
                                                  </span>
                                                  <span v-if="info.state"
                                                    ><span
                                                      style="
                                                        font-size: 15px;
                                                        font-family: kumbhSemibold;
                                                      "
                                                      >State: </span
                                                    >{{ info.state }}</span
                                                  >
                                                  <span
                                                    v-if="
                                                      !info.address &&
                                                      !info.city &&
                                                      !info.state
                                                    "
                                                    ><span
                                                      style="
                                                        font-size: 15px;
                                                        font-family: kumbhSemibold;
                                                      "
                                                      >Address not fetched</span
                                                    >
                                                  </span>
                                                  <p
                                                    v-if="
                                                      info.description != ''
                                                    "
                                                    style="
                                                      font-size: 16px;
                                                      color: black !important;
                                                    "
                                                  >
                                                    <b>Description</b>
                                                    <span>
                                                      <br />
                                                      {{
                                                        info.description.slice(
                                                          0,
                                                          100
                                                        )
                                                      }}
                                                    </span>
                                                  </p>
                                                </v-col>
                                              </v-row>
                                            </v-container>
                                          </v-card>
                                        </v-timeline-item>
                                        <v-timeline-item
                                          v-if="info.attendanceType == 'logout'"
                                          color="#005f32"
                                          fill-dot
                                          left
                                        >
                                          <span
                                            slot="opposite"
                                            style="
                                              font-family: kumbhMedium;
                                              color: black;
                                            "
                                          >
                                            <span
                                              v-if="info.applyMode == 'auto'"
                                              >Auto Punchout</span
                                            >
                                            <span v-else> Punch Out</span>
                                            <br />
                                            <br />
                                            <span
                                              style="
                                                font-size: 16px;
                                                font-family: kumbhSemibold;
                                              "
                                            >
                                              {{
                                                convertUTCToLocal(
                                                  info.attendanceTime
                                                )
                                              }}</span
                                            ></span
                                          >
                                          <v-card>
                                            <v-card-title
                                              style="background-color: #005f32"
                                            >
                                            </v-card-title>
                                            <v-container>
                                              <v-row>
                                                <v-col
                                                  cols="12"
                                                  md="10"
                                                  class="text-left"
                                                >
                                                  <span v-if="info.address"
                                                    ><span
                                                      style="
                                                        font-size: 15px;
                                                        font-family: kumbhSemibold;
                                                      "
                                                      >Address: </span
                                                    >{{ info.address }}
                                                    <br />
                                                  </span>
                                                  <span v-if="info.city"
                                                    ><span
                                                      style="
                                                        font-size: 15px;
                                                        font-family: kumbhSemibold;
                                                      "
                                                      >City: </span
                                                    >{{ info.city }}
                                                    <br />
                                                  </span>
                                                  <span v-if="info.state"
                                                    ><span
                                                      style="
                                                        font-size: 15px;
                                                        font-family: kumbhSemibold;
                                                      "
                                                      >State: </span
                                                    >{{ info.state }}</span
                                                  >
                                                  <span
                                                    v-if="
                                                      !info.address &&
                                                      !info.city &&
                                                      !info.state
                                                    "
                                                    ><span
                                                      style="
                                                        font-size: 15px;
                                                        font-family: kumbhSemibold;
                                                      "
                                                      >Address not fetched</span
                                                    >
                                                  </span>
                                                  <p
                                                    v-if="
                                                      info.description != ''
                                                    "
                                                    style="
                                                      font-size: 16px;
                                                      color: black !important;
                                                    "
                                                  >
                                                    <b>Description</b>
                                                    <span>
                                                      <br />
                                                      {{
                                                        info.description.slice(
                                                          0,
                                                          100
                                                        )
                                                      }}
                                                    </span>
                                                  </p>
                                                </v-col>
                                              </v-row>
                                            </v-container>
                                          </v-card>
                                        </v-timeline-item>
                                      </v-timeline>
                                    </v-flex>
                                  </v-layout>
                                </v-flex>
                              </v-layout>
                            </v-card>
                          </v-dialog>
                        </v-flex>
                      </v-layout>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-flex>
        </v-layout>
        <v-layout wrap v-else>
          <v-flex xs12 align-self-center pa-16>
            <span class="kumbhRegular">No Data found!</span>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>

    <!-- <v-layout wrap justify-center v-if="pages > 1">
      <v-flex align-self-center>
        <div class="pt-2">
          <v-pagination
            prev-icon="mdi-menu-left"
            next-icon="mdi-menu-right"
            :length="pages"
            :total-visible="7"
            v-model="currentPage"
            color="#005f32"
          ></v-pagination>
        </div>
      </v-flex>
    </v-layout> -->
    <div v-show="attendanceArray.length > 0">
      <v-layout wrap>
        <v-flex xs12>
          <span class="itemHeading">LOCATION</span>
          <GmapMap
            :center="mapCenter"
            :zoom="15"
            style="width: 100%; height: 400px"
          >
            <GmapMarker
              v-for="(location, index) in locations"
              :key="index"
              :icon="getMarkerIcon(location)"
              :position="location.position"
              @click="showInfoWindow(location)"
            />
            <GmapInfoWindow
              :options="{
                pixelOffset: {
                  width: 0,
                  height: -35,
                },
              }"
              :position="infoWindow.position"
              :opened="infoWindow.open"
              @closeclick="infoWindow.open = false"
            >
              <div v-html="infoWindow.content"></div>
            </GmapInfoWindow>
          </GmapMap>
        </v-flex>
      </v-layout>
    </div>
    <v-dialog persistent v-model="viewImagedialog" max-width="600px">
      <v-card>
        <v-layout wrap>
          <v-flex
            xs12
            pa-4
            text-left
            align-self-center
            style="background: #005f32 !important"
          >
            <v-layout wrap>
              <v-flex xs6 align-self-center text-left>
                <span class="kumbhBold" style="color: #ffffff; font-size: 20px"
                  >View Image</span
                >
              </v-flex>
              <v-flex xs6 align-self-center text-right>
                <v-btn icon color="white" text @click="viewImagedialog = false">
                  <v-icon color="#ffffff">mdi-close</v-icon>
                </v-btn>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex xs12 pt-2>
            <v-layout wrap px-5 pb-8 justify-center>
              <v-flex xs12 align-self-center text-center>
                <v-img
                  :src="mediaURL + selectedPhoto"
                  fill-height
                  height="60%"
                  width="60%"
                />
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-card>
    </v-dialog>
  </div>
</template>
<!-- <script src="https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/markerclusterer.js"></script> -->
<script>
import axios from "axios";
// var marker;
// var marker2;
export default {
  data: () => ({
    viewImagedialog: false,
    selectedPhoto: "",
    showsnackbar: false,
    ServerError: false,
    msg: null,
    pages: 0,
    dialog: false,
    pageCount: 1,
    appLoading: false,
    currentPage: 1,
    flag: false,
    location: [],
    userId: localStorage.getItem("userid"),
    totalData: 0,
    addcat: false,
    rules: {
      required: (value) => !!value || "Required.",
    },
    attendanceArray: [],
    attendanceData: [],
    data: [],
    name: null,
    assetId: null,
    // dateFilter: new Date().toISOString().substr(0, 10),
    // date: new Date().toISOString().substr(0, 7),
    // dateFormatted: vm.formatDateMonth(new Date().toISOString().substr(0, 7)),
    // menu1: false,
    // menu2: false,
    dat: "",
    oMarker: [],
    array: [],
    place: "",
    locations: [],
    // test: this.$route.query.empid,
    mapCenter: {},
    infoWindow: {
      open: false,
      position: null,
      content: "",
    },

    //filter

    menu1: false,
    menu2: false,
    menu3: false,
    fromDate: new Date(),
    toDate: new Date(),
    day: "Last 7 days",
    days: [
      "Last 7 days",
      "Last 30 days",
      "Last 3 months",
      "Last 6 months",
      "Last year",
      "Custom",
    ],
    menu: false,

    date: new Date().toISOString().substr(0, 7),
    computedDateFormatted: "",
  }),

  mounted() {
    this.getData();
    if (this.day) {
      this.dateFilter(this.day);
    }
    // this.getList();
  },
  computed: {
    computedDateFormattedFrom() {
      return this.formatDatePicker(this.fromDate);
    },
    computedDateFormattedTo() {
      return this.formatDatePicker(this.toDate);
    },
  },

  watch: {
    date() {
      this.currentPage = 1;
      this.computedDateFormatted = this.formatDateMonth(this.date);
      
    },
    fromDate(){
      this.getData();
    },
    toDate(){
      this.getData();
    },
    status() {
      this.currentPage = 1;
      this.getData();
    },
    currentPage() {
      this.getData();
    },
  },
  beforeMount() {
    this.computedDateFormatted = this.formatDateMonth(this.date);

    if (this.computedDateFormattedFrom) {
      this.computedDateFormattedFrom.setDate(
        this.computedDateFormattedFrom.getDate() - 7
      );
      this.computedDateFormattedFrom = this.computedDateFormattedFrom
        .toISOString()
        .substr(0, 10);
    }
    if (this.computedDateFormattedTo) {
      this.computedDateFormattedTo.setDate(
        this.computedDateFormattedTo.getDate()
      );
      this.computedDateFormattedTo = this.computedDateFormattedTo
        .toISOString()
        .substr(0, 10);
    }
  },

  methods: {
    openImageDialog(photo) {
      this.selectedPhoto = photo; // Set the clicked photo
      this.viewImagedialog = true; // Open the dialog
    },
    getData() {
      this.appLoading = true;
      axios({
        url: "/attendance/each/list",
        method: "POST",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: {
          from: this.fromDate,
          to: this.toDate,
          id: this.$route.query.id,
        },
      })
        .then((response) => {
          if (response.data.status === true) {
            this.appLoading = false;
            (this.locations = []), (this.attendanceArray = response.data.data);
            this.array = response.data.data;
            const transformedData = response.data.data.map((item) => ({
              type: "login", // Assuming type for example purposes
              position: {
                lat: item.loginLocation.location[1],
                lng: item.loginLocation.location[0],
              },
              time: item.login,
              address: item.loginLocation.address,
              city: item.loginLocation.city,
              state: item.loginLocation.state,
              punchDate: item.date,
              employee: item.uid,
            }));

            // Concatenate logout locations if needed
            response.data.data.forEach((item) => {
              transformedData.push({
                type: "logout",
                position: {
                  lat: item.logoutLocation.location[1],
                  lng: item.logoutLocation.location[0],
                },
                time: item.logout,
                address: item.logoutLocation.address,
                city: item.logoutLocation.city,
                state: item.logoutLocation.state,
                punchDate: item.date,
                employee: item.uid,
              });
            });

            this.locations = transformedData;
            if (transformedData.length > 0) {
              this.mapCenter = transformedData[0].position;
            }
          } else {
            // Handle error or empty response
            this.appLoading = false;
            console.error("Failed to fetch data:", response.data.msg);
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    eachEmployee() {
      axios({
        method: "POST",
        url: "/attendance/each/list/excel",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: {
          from: this.fromDate,
          to: this.toDate,
          id: this.$route.query.id,
        },
        responseType: "blob", // Specify the response type as blob
      })
        .then((response) => {
          // Ensure the correct MIME type for Excel files
          const contentType =
            response.headers["content-type"] ||
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
          const blob = new Blob([response.data], { type: contentType });
          const url = window.URL.createObjectURL(blob);

          // Create a temporary anchor element
          const anchor = document.createElement("a");
          anchor.href = url;
          anchor.target = "_blank"; // Open in a new tab
          anchor.download = "Employee_Attendance_Report.xlsx"; // Set the file name

          // Programmatically click on the anchor to download the file
          anchor.click();

          // Clean up
          window.URL.revokeObjectURL(url);
        })
        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },
    // getList() {
    //   var data1 = {};
    //   data1["id"] = this.$route.query.id;
    //   // You may need to adjust the date formatting based on your requirements
    //   if (this.computedDateFormatted !== "") {
    //     data1["month"] = this.computedDateFormatted;
    //   }
    //   axios({
    //     method: "POST",
    //     url: "/attendance/each/list",
    //     data: data1,
    //     headers: {
    //       "x-auth-token": localStorage.getItem("token"),
    //     },
    //   })
    //     .then((response) => {
    //       if (response.data.status === true) {
    //         this.attendanceArray = response.data.data;
    //         this.array = response.data.data;
    //         const transformedData = response.data.data.map((item) => ({
    //           type: "login", // Assuming type for example purposes
    //           position: {
    //             lat: item.loginLocation.location[1],
    //             lng: item.loginLocation.location[0],
    //           },
    //           time: item.login,
    //           address: item.loginLocation.address,
    //           city: item.loginLocation.city,
    //           state: item.loginLocation.state,
    //           punchDate: item.date,
    //           employee: item.uid,
    //         }));

    //         // Concatenate logout locations if needed
    //         response.data.data.forEach((item) => {
    //           transformedData.push({
    //             type: "logout",
    //             position: {
    //               lat: item.logoutLocation.location[1],
    //               lng: item.logoutLocation.location[0],
    //             },
    //             time: item.logout,
    //             address: item.logoutLocation.address,
    //             city: item.logoutLocation.city,
    //             state: item.logoutLocation.state,
    //             punchDate: item.date,
    //             employee: item.uid,
    //           });
    //         });

    //         this.locations = transformedData;
    //         if (transformedData.length > 0) {
    //           this.mapCenter = transformedData[0].position;
    //         }
    //       } else {
    //         // Handle error or empty response
    //         console.error("Failed to fetch data:", response.data.msg);
    //       }
    //     })
    //     .catch((error) => {
    //       console.error("Error fetching data:", error);
    //     });
    // },
    showInfoWindow(location) {
      this.infoWindow.position = location.position;
      if (location === undefined) {
        this.place = "Address not fetched";
      } else {
        if (!location.address && !location.city && !location.state) {
          this.place = "Address not fetched";
        } else {
          this.place =
            (location.address || "") +
            " " +
            (location.city || "") +
            " " +
            (location.state || "");
        }
      }
      var type = "";
      if (location.type == "login") type = "Punch In";
      else type = "Punch Out";
      this.infoWindow.content = `
        <div>
          <h4>${type}</h4>
          </br>
          <span>Date: ${location.punchDate}</span></br>
          <span>Time: ${this.convertUTCToLocal(location.time)}</span></br>
          <span>Address: ${this.place}</span>
        </div>
      `;
      this.infoWindow.open = true;
    },
    getMarkerIcon(location) {
      // Define your logic for choosing different colored icons
      if (location.type === "login") {
        return "http://maps.google.com/mapfiles/ms/icons/green-dot.png"; // URL to your custom marker icon
      } else {
        return "http://maps.google.com/mapfiles/ms/icons/red-dot.png"; // URL to your default marker icon
      }
    },
    getView(item) {
      var dt, day, year, strTime, month;
      var ss = item.date;
      item.date = ss.split("-").reverse().join("-");
      console.log(item.date);

      dt = new Date(item.date);
      day = dt.getDate();
      month = dt.getMonth() + 1;
      year = dt.getFullYear();
      year = year.toString();
      // year = year.slice(2, 4)
      strTime = year + "-" + month + "-" + day;
      this.dat = strTime;
      axios({
        url: "/employee/attendance/list",
        method: "POST",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: {
          id: item.uid,
          date: this.dat,
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.data = response.data.data;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    clearData() {
      (this.data = []), this.getData();
    },
    convertUTCToLocal(utcDateString) {
      var utcDate = new Date(utcDateString);
      const hours = utcDate.getHours();
      const minutes = utcDate.getMinutes().toString().padStart(2, "0");
      const seconds = utcDate.getSeconds().toString().padStart(2, "0");
      //   const amPm = hours >= 12 ? "PM" : "AM";

      // Adjust hours for 12-hour format (convert from 24-hour to 12-hour)
      const adjustedHours = hours % 12 || 12;

      // Format the time string (HH:MM AM/PM)
      const formattedTime = `${adjustedHours}:${minutes}:${seconds}`;

      // Return the date and time as separate objects
      return formattedTime;
      // return {
      //   date: formattedDate,
      //   time: formattedTime
      // };
    },

    // filter
    dateFilter(day) {
      var to = new Date();
      var from = new Date(to.getTime());
      if (day == "Last 7 days") {
        from = from.setDate(from.getDate() - 7);
        from = new Date(from);
      } else if (day == "Last 30 days") {
        from = from.setDate(from.getDate() - 30);
        from = new Date(from);
      } else if (day == "Last 3 months") {
        from = from.setMonth(from.getMonth() - 3);
        from = new Date(from);
      } else if (day == "Last 6 months") {
        from = from.setMonth(from.getMonth() - 6);
        from = new Date(from);
      } else if (day == "Last year") {
        from = from.setMonth(from.getMonth() - 12);
        from = new Date(from);
      }
      // else if (day == "Lifetime") {
      //   from = "";
      //   to = "";
      // }
      this.fromDate = from.toISOString().substr(0, 10);
      this.toDate = to.toISOString().substr(0, 10);
      this.getData();
    },
    checkDate() {
      // let ndate = new Date().toISOString().substr(0, 10);
      // if (this.fromDate == ndate) {

      var m = new Date(this.fromDate);
      // var j = new Date(this.fromDate);
      var n = new Date(this.toDate);
      if (m <= n && n >= m) {
        const tomorrow = new Date(this.toDate);
        tomorrow.setDate(tomorrow.getDate() - 15);
        // this.fromDate = tomorrow.toISOString().substr(0, 10);
        // }
        // n.setDate(j.getDate() + 13);
        if (n - m < 1296000000) {
          // console.log("GH");
          // console.log("M=", m);
          // console.log("N=", n);
          // console.log("res", n - m);
          n.setDate(n.getDate());
          // console.log("Ms=", m.toISOString().substr(0, 10));
          // console.log("Ns=", n.toISOString().substr(0, 10));
          this.toDate = n.toISOString().substr(0, 10);
          // console.log("GH", this.fromDate);
          this.getData();
        } else {
          // console.log("els casssse");
          // console.log(m);
          // console.log(n);
          m.setMonth(n.getMonth());
          m.setFullYear(n.getFullYear());
          m.setDate(n.getDate() - 15);
          // n.setMonth(m.getMonth());
          // n.setFullYear(m.getFullYear());
          // n.setDate(m.getDate() + 15);
          // console.log("fin", n);
          // console.log("Msss=", m.toISOString().substr(0, 10));
          // console.log("Nsss=", n.toISOString().substr(0, 10));
          // console.log("els case", m.getMonth());
          this.fromDate = m.toISOString().substr(0, 10);
          this.toDate = n.toISOString().substr(0, 10);
          this.getData();
        }
      } else {
        n.setMonth(m.getMonth());
        n.setFullYear(m.getFullYear());
        n.setDate(m.getDate() + 15);
        this.toDate = n.toISOString().substr(0, 10);
        this.getData();
      }
    },
    checkDate1() {
      var m = new Date(this.fromDate);
      var n = new Date(this.toDate);
      if (m <= n && n >= m) {
        if (n - m < 1296000000) {
          // console.log("GH");
          // console.log("M=", m);
          // console.log("N=", n);
          // console.log("res", n - m);
          n.setDate(n.getDate());
          // console.log("Ms=", m.toISOString().substr(0, 10));
          // console.log("Ns=", n.toISOString().substr(0, 10));
          this.toDate = n.toISOString().substr(0, 10);
          // console.log("GH", this.fromDate);
          this.getData();
        } else {
          // console.log("els casssse");
          // console.log(m);
          // console.log(n);
          n.setMonth(m.getMonth());
          n.setFullYear(m.getFullYear());
          n.setDate(m.getDate() + 7);
          // m.setMonth(n.getMonth());
          // m.setFullYear(n.getFullYear());
          // m.setDate(n.getDate() - 15);
          // console.log("fin", m);
          // console.log("els case", n.getMonth());
          this.fromDate = m.toISOString().substr(0, 10);
          this.toDate = n.toISOString().substr(0, 10);
          this.getData();
        }
      } else {
        m.setMonth(n.getMonth());
        m.setFullYear(n.getFullYear());
        m.setDate(n.getDate() - 7);
        this.fromDate = m.toISOString().substr(0, 10);

        this.getData();
      }
    },
    formatDate(item) {
      var defDate = new Date(item);
      defDate = defDate.toString().slice(0, 15);
      var today = new Date();
      today = today.toString().slice(0, 15);

      var yesterday = new Date();
      yesterday.setDate(yesterday.getDate() - 1);
      yesterday = yesterday.toString().slice(0, 15);

      var dt = new Date(item);
      var day = dt.getDate();
      var year = dt.getFullYear();
      dt = dt.toString();
      var strTime = dt.slice(0, 4) + day + " " + dt.slice(4, 7) + " " + year;

      if (today == defDate) {
        return "Today";
      } else if (yesterday == defDate) {
        return "Yesterday";
      } else {
        return strTime;
      }
    },
    formatDateMonth(date) {
      if (!date) return null;
      const [year, month] = date.split("-");
      return `${month}-${year}`;
    },
    formatDatePicker(date) {
      var dt = new Date(date);
      var day = String(dt.getDate()).padStart(2, "0"); // Ensure two digits
      var month = String(dt.getMonth() + 1).padStart(2, "0"); // Ensure two digits
      var year = dt.getFullYear();
      var strTime = day + "-" + month + "-" + year;
      return strTime;
    },
  },
};
</script>
<style>
.tableHead {
  font-size: 15px;
  color: #ffffff;
  font-family: kumbhMedium;
}

#map {
  height: 400px !important;
  width: 100%;
}
</style>
      